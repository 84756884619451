/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "./idea.css";
import HeaderDark from "../../../common/header/HeaderDark";
import FooterOne from "../../../common/footer/FooterOne";
import ReactPlayer from "react-player";
import Takeaction from "../../pages/Form/takeactionform";

import herobg from "../../../../src/app/assets/images/idea-to-Product/hero-bg.webp";
import set1 from "../../../app/assets/images/idea-to-Product/Research.webm";
import set2 from "../../../app/assets/images/idea-to-Product/Prototype-Design.webm";
import set3 from "../../../app/assets/images/idea-to-Product/Investments.webm";
import set4 from "../../../app/assets/images/idea-to-Product/Development.webm";
import set5 from "../../../app/assets/images/idea-to-Product/Launch.webm";
import set6 from "../../../app/assets/images/idea-to-Product/Protect.webm";
import set7 from "../../../app/assets/images/idea-to-Product/Planning.webm";
import set8 from "../../../app/assets/images/idea-to-Product/Support.webm";
import set9 from "../../../app/assets/images/idea-to-Product/Time.webm";

import idea1 from "../../../../src/app/assets/images/idea-to-Product/idea1.png";
import idea2 from "../../../../src/app/assets/images/idea-to-Product/idea2.png";
import idea3 from "../../../../src/app/assets/images/idea-to-Product/idea3.png";
import idea4 from "../../../../src/app/assets/images/idea-to-Product/idea4.png";

import serviceipad from "../../../../src/app/assets/images/idea-to-Product/service-ipad.jpg";
import servicemobile from "../../../../src/app/assets/images/idea-to-Product/service-mobile.jpg";

// testimonials import
import client1 from "../../../../src/app/assets/images/idea-to-Product/client1.png";
import client2 from "../../../../src/app/assets/images/idea-to-Product/client2.png";
import client3 from "../../../../src/app/assets/images/idea-to-Product/client3.png";
import client4 from "../../../../src/app/assets/images/idea-to-Product/client4.png";
import client5 from "../../../../src/app/assets/images/idea-to-Product/client5.png";
import client6 from "../../../../src/app/assets/images/idea-to-Product/client6.png";

import getintouch from "../../../../src/app/assets/images/idea-to-Product/get-in-touch.png";

const Products = () => {
/*Scrol slider starts */
const [scrollPercentage, setScrollPercentage] = useState(0);
const [activeTab, setActiveTab] = useState("scroller1");

useEffect(() => {
function handleScroll() {
const scrollTop =
window.pageYOffset || document.documentElement.scrollTop;
const scrollHeight = document.documentElement.scrollHeight;
const windowHeight = window.innerHeight;
const newScrollPercentage = Math.floor(
(scrollTop / (scrollHeight - windowHeight)) * 100
);

const container = document.getElementById("scrollcontain-main");
if (container) {
const sections = container.querySelectorAll(".page-section");
const sectionTops = Array.from(sections).map((section) => ({
id: section.id,
top: section.getBoundingClientRect().top,
}));
const currentSection = sectionTops.find((section) => section.top >= 0);
if (currentSection) {
setActiveTab(currentSection.id);
}
}
}

document.addEventListener("scroll", handleScroll);

return () => {
document.removeEventListener("scroll", handleScroll);
};
}, []);

const handleTabClick = (id) => {
const section = document.getElementById(id);
if (section) {
section.scrollIntoView({ behavior: "smooth" });
setActiveTab(id);
} else {
console.error(`Element with id ${id} not found.`);
}
};

const properties = {
prevArrow: (
<button className="cs-btn idp-test_btn cst-left">
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left"
    viewBox="0 0 16 16">
    <path fill-rule="evenodd"
      d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8" />
  </svg>
</button>
),
nextArrow: (
<button className="cs-btn idp-test_btn cst-right">
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right"
    viewBox="0 0 16 16">
    <path fill-rule="evenodd"
      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
  </svg>
</button>
),
};
/*Scroll slider ends */

return (
<div>
  <HeaderDark />
  <div className="ideatoproduct-page">
    <section class="home-sec hero-bg margin-minus">
      <div class="container custom-cn-width position-relative z-index-1">
        <div class="row align-items-center ">
          <div class="col-lg-7 pe-lg-0 text-lg-start text-center">
            <h1 class="mb-4 mb-lg-5">
              Let’s transform your{" "}
              <span class="text-bk-dark">software</span> or{" "}
              <span class="text-bk-dark">app</span> idea to reality
            </h1>
          </div>
          <div class="col-lg-5">
            <img src={herobg} class="img-fluid" alt="Hero Banner" />
          </div>
        </div>
      </div>
    </section>
    <section class="section-padding">
      <div class="container custom-cn-width">
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-7 text-lg-start text-center">
            <h1 class="title">
              How we make your app or software idea a reality?
            </h1>
          </div>
          <div class="col-lg-5 text-lg-start text-center">
            <p class=" pb-4 mx-lg-4 font-size-20">
              There are a lot of steps involved in turning an idea into a
              tangible product and if you don’t take the right steps your
              product may not make it in the market. There are several
              essential steps that need to be taken to ensure your product
              is successful and at CAP Digisoft we can help you every step
              of the way to ensure success. Feel rest assured CAP Digisoft
              is experienced and capable of developing and supporting your
              idea all the way from concept to product release and beyond.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="position-relative section-padding pt-0">
      <div className="container-fluid container-fluid-lg">
        <div className="scroll-navigator">
          {/* <div className="bar"
            style={{ position: 'absolute', width: '5px', height: `${scrollPercentage * 1.08}%`, background: '#aaa', top: 0, left: 0, borderRadius: '100vmax', transition: 'height 150ms ease-in' }}>
          </div> */}
          <ul className="menu" style={{ listStyleType: "none" }}>
            <li>
              <a href="javascript:void(0)" className={activeTab==="scroller1" ? "active" : "" } onClick={()=>
                handleTabClick("scroller1")}
                >
                {" "}
                <span className="for-lg">01. Pre-Development Research</span>
                <span className="for-md">Step 1</span>
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" className={activeTab==="scroller2" ? "active" : "" } onClick={()=>
                handleTabClick("scroller2")}
                >
                <span className="for-lg">
                  02. Prototype Design & Development
                </span>
                <span className="for-md">Step 2</span>
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" className={activeTab==="scroller3" ? "active" : "" } onClick={()=>
                handleTabClick("scroller3")}
                >
                <span className="for-lg">03. Procure Investments</span>
                <span className="for-md">Step 3</span>
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" className={activeTab==="scroller4" ? "active" : "" } onClick={()=>
                handleTabClick("scroller4")}
                >
                <span className="for-lg">04. Product Development</span>
                <span className="for-md">Step 4</span>
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" className={activeTab==="scroller5" ? "active" : "" } onClick={()=>
                handleTabClick("scroller5")}
                >
                <span className="for-lg">05. Product Launch Planning</span>
                <span className="for-md">Step 5</span>
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" className={activeTab==="scroller6" ? "active" : "" } onClick={()=>
                handleTabClick("scroller6")}
                >
                <span className="for-lg">
                  06. Protect Your Intellectual
                </span>
                <span className="for-md">Step 6</span>
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" className={activeTab==="scroller7" ? "active" : "" } onClick={()=>
                handleTabClick("scroller7")}
                >
                <span className="for-lg">07. Product Launch Execution</span>
                <span className="for-md">Step 7</span>
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" className={activeTab==="scroller8" ? "active" : "" } onClick={()=>
                handleTabClick("scroller8")}
                >
                <span className="for-lg">08. Post-Launch Support</span>
                <span className="for-md">Step 8</span>
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" className={activeTab==="scroller9" ? "active" : "" } onClick={()=>
                handleTabClick("scroller9")}
                >
                <span className="for-lg">
                  09. Time to Spread Your Wings
                </span>
                <span className="for-md">Step 9</span>
              </a>
            </li>
          </ul>
        </div>
        <div id="scrollcontain-main" className="scroll-navigator-content">
          <section id="scroller1" className="page-section">
            <div class="row">
              <div class="col-lg-12">
                <h3 class="mb-3 title-inner">Pre-Development Research</h3>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-4">
                <ReactPlayer className="img-fluid" playing={true} loop={true} url={set1} />
              </div>
              <div class="col-lg-8">
                <div class="row align-items-center">
                  <div class="col-xxl-4 col-lg-5">
                    <h4 class="sub-title">Challenge</h4>
                  </div>
                  <div class="col-xl-8 col-lg-7">
                    <p>
                      It is super important to do an initial investigation
                      to make sure your idea doesn’t infringe on existing
                      copyright, intellectual property, patents and to test
                      if the market is in need of your product.
                    </p>
                  </div>
                </div>
               
               
                <hr class="my-4" />
                <div class="row align-items-center">
                  <div class="col-xxl-4 col-lg-5">
                    <h4 class="sub-title">Solution</h4>
                  </div>
                  <div class="col-xl-8 col-lg-7">
                    <p>
                      Don’t worry about the headaches of trying to find this
                      information by yourself, at CAP Digisoft we size up
                      any competition and do market research to see whether
                      your idea is unique enough to stand out. This process
                      also helps the team develop a common vision and makes
                      sure everyone is aligned throughout the development
                      and launch of your product.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="scroller2" className="page-section">
            <div class="row">
              <div class="col-lg-12">
                <h3 class="mb-3 title-inner">
                  Prototype Design &amp; Development
                </h3>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-4">
                <ReactPlayer className="img-fluid" playing={true} loop={true} url={set2} />
              </div>
              <div class="col-lg-8">
                <div class="row align-items-center">
                  <div class="col-xxl-4 col-lg-5">
                    <h4 class="sub-title">Challenge</h4>
                  </div>
                  <div class="col-xl-8 col-lg-7">
                    <p>
                      A minimum viable product is vital to make sure the
                      market is ready for your product.
                    </p>
                  </div>
                </div>
               
                <hr class="my-4" />
                <div class="row align-items-center">
                  <div class="col-xxl-4 col-lg-5">
                    <h4 class="sub-title">Solution</h4>
                  </div>
                  <div class="col-xl-8 col-lg-7">
                    <p>
                      When we’ve made sure the path forward is clear, our
                      team of design and development experts will work
                      closely with you to design a minimum viable product
                      that we can show to potential investors. This design
                      plays a critical role in the future of procuring
                      investments by clearly articulating your idea and
                      impressing investors.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="scroller3" className="page-section">
            <div class="row">
              <div class="col-lg-12">
                <h3 class="mb-3 title-inner">Procure Investments</h3>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-4">
                <ReactPlayer className="img-fluid" playing={true} loop={true} url={set3} />
              </div>
              <div class="col-lg-8">
                <div class="row align-items-center">
                  <div class="col-xxl-4 col-lg-5">
                    <h4 class="sub-title">Challenge</h4>
                  </div>
                  <div class="col-xl-8 col-lg-7">
                    <p>
                      With the market research and prototype design
                      finished, we will help you attract investments.
                    </p>
                  </div>
                </div>
                
                <hr class="my-4" />
                <div class="row align-items-center">
                  <div class="col-xxl-4 col-lg-5">
                    <h4 class="sub-title">Solution</h4>
                  </div>
                  <div class="col-xl-8 col-lg-7">
                    <p>
                      At CAP Digisoft we help you create your investment
                      campaign and connect you with angel investors. Through
                      your partnership with us you can use our technical
                      expertise and product launch expertise which will give
                      a great impression to possible investors and make them
                      confident in your product.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="scroller4" className="page-section">
            <div class="row">
              <div class="col-lg-12">
                <h3 class="mb-3 title-inner">Product Development</h3>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-4">
                <ReactPlayer className="img-fluid" playing={true} loop={true} url={set4} />
              </div>
              <div class="col-lg-8">
                <div class="row align-items-center">
                  <div class="col-xxl-4 col-lg-5">
                    <h4 class="sub-title">Challenge</h4>
                  </div>
                  <div class="col-xl-8 col-lg-7">
                    <p>
                      Through the fundraising and capital that we have
                      procured from investors, we will have the freedom to
                      complete the full development of your minimum viable
                      product (MVP). This is crucial in order to find out
                      what features of the product are unnecessary and what
                      features are keeping the users engaged.
                    </p>
                  </div>
                </div>
                
                <hr class="my-4" />
                <div class="row align-items-center">
                  <div class="col-xxl-4 col-lg-5">
                    <h4 class="sub-title">Solution</h4>
                  </div>
                  <div class="col-xl-8 col-lg-7">
                    <p>
                      During this phase we will present the MVP to the beta
                      users, get their feedback, and enhance the product
                      user experience. This means that you can rely on our
                      team of development experts to help you bring your
                      product to market quickly and efficiently. With CAP
                      Digisoft by your side, you can be assured that your
                      MVP will be completed according to your specifications
                      and enhanced to the highest level of quality according
                      to user feedback.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="scroller5" className="page-section">
            <div class="row">
              <div class="col-lg-12">
                <h3 class="mb-3 title-inner">Product Launch Planning</h3>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-4">
                <ReactPlayer className="img-fluid" playing={true} loop={true} url={set5} />
              </div>
              <div class="col-lg-8">
                <div class="row align-items-center">
                  <div class="col-xxl-4 col-lg-5">
                    <h4 class="sub-title">Challenge</h4>
                  </div>
                  <div class="col-xl-8 col-lg-7">
                    <p>
                      Without a product launch strategy or a team with
                      experience in product launches, reaching the market
                      with your product can end up being a huge failure. It
                      is extremely important to have the right team in order
                      for your product to be a success.
                    </p>
                  </div>
                </div>
               
                <hr class="my-4" />
                <div class="row align-items-center">
                  <div class="col-xxl-4 col-lg-5">
                    <h4 class="sub-title">Solution</h4>
                  </div>
                  <div class="col-xl-8 col-lg-7">
                    <p>
                      Our marketing team has many diverse experiences in our
                      19 years of helping people turn their ideas into a
                      reality. They start right away on crafting the
                      necessary marketing to sell your final product
                      including a concise sales pitch with a value
                      statement, statistics and other data, and developing a
                      way to talk about your product’s uniqueness. We can
                      develop supplemental marketing, including a website to
                      compliment your product, your own unique branding for
                      your company, collateral pieces, video content, and
                      more!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="scroller6" className="page-section">
            <div class="row">
              <div class="col-lg-12">
                <h3 class="mb-3 title-inner">Protect Your Intellectual</h3>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-4">
                <ReactPlayer className="img-fluid" playing={true} loop={true} url={set6} />
              </div>
              <div class="col-lg-8">
                <div class="row align-items-center">
                  <div class="col-xxl-4 col-lg-5">
                    <h4 class="sub-title">Challenge</h4>
                  </div>
                  <div class="col-xl-8 col-lg-7">
                    <p>
                      If we see a good response for your product, we'll need
                      to work on securing intellectual property rights prior
                      to its launch.
                    </p>
                  </div>
                </div>
                
                <hr class="my-4" />
                <div class="row align-items-center">
                  <div class="col-xxl-4 col-lg-5">
                    <h4 class="sub-title">Solution</h4>
                  </div>
                  <div class="col-xl-8 col-lg-7">
                    <p>
                      Here at CAP Digisoft, we'll join forces with a legal
                      team to secure intellectual property rights,
                      trademarks, copyright, and patents necessary to
                      protect your intellectual property. We understand the
                      fear of having your brilliant idea stolen, so we start
                      every idea to product journey with a confidential NDA
                      that protects you and your idea. You can be rest
                      assured that your idea is safe as we help you turn it
                      into a reality.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="scroller7" className="page-section">
            <div class="row">
              <div class="col-lg-12">
                <h3 class="mb-3 title-inner">Product Launch Execution</h3>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-4">
                <ReactPlayer className="img-fluid" playing={true} loop={true} url={set7} />
              </div>
              <div class="col-lg-8">
                <div class="row align-items-center">
                  <div class="col-xxl-4 col-lg-5">
                    <h4 class="sub-title">Challenge</h4>
                  </div>
                  <div class="col-xl-8 col-lg-7">
                    <p>
                      Having a solid business plan is critical for success
                      in any product launch, unfortunately a lot of startups
                      lack the experience to create a solid business plan
                      and end up failing as a result.
                    </p>
                  </div>
                </div>
                
               
                <hr class="my-4" />
                <div class="row align-items-center">
                  <div class="col-xxl-4 col-lg-5">
                    <h4 class="sub-title">Solution</h4>
                  </div>
                  <div class="col-xl-8 col-lg-7">
                    <p>
                      At CAP Digisoft we work with you to create a product
                      release plan, set a product release date, and build
                      awareness about the product to hype up the public. We
                      also ensure that the product release is tied closely
                      with the marketing plan so that all messaging is
                      aligned.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="scroller8" className="page-section">
            <div class="row">
              <div class="col-lg-12">
                <h3 class="mb-3 title-inner">Post-Launch Support</h3>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-4">
                <ReactPlayer className="img-fluid" playing={true} loop={true} url={set8} />
              </div>
              <div class="col-lg-8">
                <div class="row align-items-center">
                  <div class="col-xxl-4 col-lg-5">
                    <h4 class="sub-title">Challenge</h4>
                  </div>
                  <div class="col-xl-8 col-lg-7">
                    <p>
                      It is very important to collect user feedback after
                      the launch and adjust your product according to the
                      data over time.
                    </p>
                  </div>
                </div>
                
                <hr class="my-4" />
                <div class="row align-items-center">
                  <div class="col-xxl-4 col-lg-5">
                    <h4 class="sub-title">Solution</h4>
                  </div>
                  <div class="col-xl-8 col-lg-7">
                    <p>
                      Our support for your product does not stop at the
                      launch. We receive feedback from customers to
                      continually validate your idea so we can improve your
                      product according to data from real consumers. This is
                      important so your product keeps up with market
                      expectations and continues to be successful.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="scroller9" className="page-section">
            <div class="row">
              <div class="col-lg-12">
                <h3 class="mb-3 title-inner">Time to Spread Your Wings</h3>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-4">
                <ReactPlayer className="img-fluid" playing={true} loop={true} url={set9} />
              </div>
              <div class="col-lg-8">
                <div class="row align-items-center">
                  <div class="col-xxl-4 col-lg-5">
                    <h4 class="sub-title">Challenge</h4>
                  </div>
                  <div class="col-xl-8 col-lg-7">
                    <p>
                      At this point, you will have partnership experience
                      with us, but perhaps it is now time for your company
                      to develop by itself.
                    </p>
                  </div>
                </div>
                
                <hr class="my-4" />
                <div class="row align-items-center">
                  <div class="col-xxl-4 col-lg-5">
                    <h4 class="sub-title">Solution</h4>
                  </div>
                  <div class="col-xl-8 col-lg-7">
                    <p>
                      To kick off, we can join as a technical co-founder and
                      offer you consulting assistance. If you are thinking
                      about establishing your own team, we can also help you
                      find the right people. We aren't going to be available
                      all the time now, but we will make it easier for you
                      to get the help and talent you need to continue
                      growing.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
    <section class="turn-idea-sec bg-dark section-padding">
      <div class="container custom-cn-width">
        <div class="row justify-content-center text-center">
          <div class="col-lg-9">
            <h1 class="title pb-lg-4 text-white">
              Turning an idea into a product starts with action
            </h1>
            <h2 class="text-white mb-4 sub-title">
              Act before it’s too late!
            </h2>
            <a href="idea-to-product-form" class="default-btn orange-btn mt-3" data-bs-toggle="modal"
                data-bs-target="#meeting">
              Book a Meeting
            </a>
          </div>
        </div>
      </div>
    </section>
    <section class="turn-idea-sec section-padding">
      <div class="container custom-cn-width">
        <div class="row justify-content-around">
          <div class="col-lg-4">
            <h1 class="title pe-lg-4">
              Discover the CAP Digisoft Difference
            </h1>
            <p class=" ">
              Our team of dedicated developers, marketers, and product
              planners can help you pave the way to bring your idea to
              reality. Contact us today for a no-obligation, confidential
              consultation.
            </p>
          </div>
          <div class="col-lg-3 py-lg-0 py-4">
            <h5 class="title mb-2">Easy Engagement</h5>
            <p class=" ">
              Having a one-door solution from CAP Digisoft makes it much
              simpler to develop an idea into a product. Instead of trying
              to do all the research and hiring yourself, we save you the
              time and track down the perfect resources for you. Because of
              our 19 years of expertise, we know how to find the right
              individual for each job, and what steps need to be taken for
              success. You can also relax and remember that for every idea
              to product project we assist, we sign an NDA so your unique
              idea is safe and sound.
            </p>
            <a href="idea-to-product-form" class="default-btn cust-btn-primary light-variant orange-btn mt-3" data-bs-toggle="modal"
                data-bs-target="#meeting">
              Book a Meeting
            </a>
          </div>
          <div class="col-lg-3 pb-lg-0 pb-4">
            <h5 class="title mb-2">Budget Friendly</h5>
            <p class=" ">
              {" "}
              When you have an idea for a product, but don't have the skills
              or resources to bring it to market yourself, outsourcing help
              from CAP Digisoft can be extremely affordable and provide the
              best possible chance for success. We offer a wide range of
              services that can take your idea from a concept to a finished
              product, all for a fraction of the cost of hiring in-house
              staff. With more than 19 years of experience in idea to
              product development, we're experts in bringing new products to
              life quickly and cost-effectively.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="service-sec-bg px-0 px-lg-3 section-padding overflow-hidden pt-sm-0">
      <div class="container custom-cn-width">
        <div class="row justify-content-start align-items-center text-center py-3 py-lg-5 my-lg-5 pt-sm-0">
          <div class="col-lg-6 mb-0 mb-lg-0 text-start">
            <h1 class="fw-light text-lg-start title">
              Here's a few of the unique ideas we've brought to reality
            </h1>
            <div class="logo-sec mt-lg-5">
              <div class="row g-5 justify-content-start align-items-center text-start ">
                <div class="col-4 col-lg-5 ">
                  <img src={idea1} alt="Jugl" class="img-fluid" />
                </div>
                <div class="col-7 col-lg-6 ">
                  <img src={idea2} alt="Jugl" class="img-fluid" />
                </div>

                <div class="col-4 col-lg-5">
                  <img src={idea3} alt="Jugl" class="img-fluid" />
                </div>
                <div class="col-7 col-lg-6">
                  <img src={idea4} alt="Jugl" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 mb-0 mb-lg-5 text-start px-0 mt-5 pt-sm-0">
           
            <img class="img-fluid d-lg-none d-md-none d-sm-block" src={servicemobile} alt="" />
          </div>
        </div>
      </div>
    </section>
    <section className="testimonial-sec section-padding">
      <div className="container custom-cn-width">
        <Slide className="brand-slider px-0" {...properties} slidesToScroll={1} slidesToShow={1} indicators={false}
          autoplay={false}>
          <div class="each-slide-effect">
            <div class="item row justify-content-center align-items-center d-flex">
              <div class="col-lg-2 testimonial-data text-center">
                <h1 class="quotes text-start">‘’</h1>
                <img src={client1} alt="Terry Johnson" class="img-fluid br-53" width="120px" />
              </div>
              <div class="col-lg-10 testimonial-content pe-lg-5">
                <p class=" ">Terry Johnson</p>
                <p class="text-muted mb-3">
                  <em>Generational Equity</em>{" "}
                </p>
                <h5 class="mb-3">
                  I want to thank you for all that you do, week in and week
                  out, helping us fight the battle to bring in revenue and
                  new clients. You work with diligence, excellence and
                  integrity, and I’m grateful for you. You help us make a
                  difference in the lives of business owners and their
                  families across North America, in the lives of our team,
                  and in my life as well. I am most appreciative, and
                  thankful.
                </h5>
              </div>
            </div>
          </div>
          <div class="each-slide-effect">
            <div class="item row justify-content-center align-items-center d-flex">
              <div class="col-lg-2 testimonial-data text-center">
                <h1 class="quotes text-start">‘’</h1>
                <img src={client2} alt="Terry Johnson" class="img-fluid br-53" width="120px" />
              </div>
              <div class="col-lg-10 testimonial-content pe-lg-5">
                <p class="mb-4">Anil Keswani</p>
                <h5 class="mb-0">
                  CAP Digisoft Team has worked on several websites for me. I
                  have not found a better company to work with. They are
                  fast and thorough. CEO has built a strong team.
                </h5>
              </div>
            </div>
          </div>
          <div class="each-slide-effect">
            <div class="owl-item active">
              <div class="item row justify-content-center align-items-center d-flex">
                <div class="col-lg-2 testimonial-data text-center">
                  <h1 class="quotes text-start">‘’</h1>
                  <img src={client3} alt="Paul Bucky" class="img-fluid br-53" width="120px" />
                </div>
                <div class="col-lg-10 testimonial-content pe-lg-5">
                  <p class="mb-4">Paul Bucky</p>
                  <h5 class="mb-0">
                    You guys just did amazing work for us, which is already
                    paying off. Thanks
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div class="each-slide-effect">
            <div class="item row justify-content-center align-items-center d-flex">
              <div class="col-lg-2 testimonial-data text-center">
                <h1 class="quotes text-start">‘’</h1>
                <img src={client4} alt="Jim Walker" class="img-fluid br-53" width="120px" />
              </div>
              <div class="col-lg-10 testimonial-content pe-lg-5">
                <p class="mb-4">Jim Walker</p>
                <h5 class="mb-0">
                  As a lawyer, I have been very careful about the internet.
                  I knew I needed to get a new website when I was shifting
                  the focus of my practice. They did an amazing job-giving
                  me a lot more than I expected. They gave me what I didn't
                  know I needed until I saw it. They even did a complete
                  redesign free when they decided their first design wasn't
                  strong enough. I didn't even have to ask. And finally,
                  having a lawyer on their team helped from my
                  perspective...
                </h5>
              </div>
            </div>
          </div>
          <div class="each-slide-effect">
            <div class="item row justify-content-center align-items-center d-flex">
              <div class="col-lg-2 testimonial-data text-center">
                <h1 class="quotes text-start">‘’</h1>
                <img src={client5} alt="Cody" class="img-fluid br-53" width="120px" />
              </div>
              <div class="col-lg-10 testimonial-content pe-lg-5">
                <p class="mb-4">Cody Ngo</p>
                <h5 class="mb-0">
                  Thank you and your team for such an outstanding job with
                  the website and met all our requirements. We appreciate
                  the professionalism and quick turnaround time!. I enjoyed
                  working with you and your team and will definitely keep
                  you guys in mind for future project.
                </h5>
              </div>
            </div>
          </div>
          <div class="each-slide-effect">
            <div class="item row justify-content-center align-items-center d-flex">
              <div class="col-lg-2 testimonial-data text-center">
                <h1 class="quotes text-start">‘’</h1>
                <img src={client6} alt="Chris" class="img-fluid br-53" width="120px" />
              </div>
              <div class="col-lg-10 testimonial-content pe-lg-5">
                <p class="mb-4">Chris</p>
                <h5 class="mb-0">
                  We have been a customer of CAP Digisoft since 2010 and can
                  tell you that we have been extremely happy with their
                  work. Not only has their team been able to deliver on
                  their assigned tasks, they also have shown a strong desire
                  to perform at the best level possible. Often offering
                  suggestions to improve upon current processes, CAP
                  Digisoft has become an invaluable partner for my business
                  and an integral part of our success. I would highly
                  recommend them to any company looking for BPO support.
                </h5>
              </div>
            </div>
          </div>
        </Slide>
      </div>
    </section>
    <section class="bg-dark section-padding">
      <div class="container custom-cn-width">
        <div class="row justify-content-around align-items-center">
          <div class="col-lg-4">
            <h1 class="title pe-lg-4 text-white">
              Who we love to work with?
            </h1>
          </div>
          <div class="col-lg-6 py-lg-0 py-4">
            <p class="text-light mb-3">
              We work with individuals and businesses who have an idea for a
              product. They should have a full budget to support development
              or have seed funding to develop an initial vision to present
              to investors.
            </p>
            <p class="text-light mb-3">
              We’re passionate about working with people who are looking to
              create something new and innovative. We have gained a lot of
              experience turning ideas like yours into real, working
              products.
            </p>
            <p class="text-light mb-2">
              If this sounds like you, contact us today to discuss your
              project needs and let us show you what we can do!
            </p>
            <a href="idea-to-product-form" class="default-btn orange-btn mt-3" data-bs-toggle="modal"
                data-bs-target="#meeting">
              Book a Meeting
            </a>
          </div>
        </div>
      </div>
    </section>
    <section class="get-in-touch-sec section-padding">
      <div class="container custom-cn-width">
        <div class="row justify-content-around">
          <div class="col-lg-8">
            <div class="row">
              <div class="col-lg-9">
                <h1 class="pe-lg-4 title">
                  Take action today and watch as your concept becomes a
                  reality through our services
                </h1>
              </div>
            </div>

            <div class="row align-items-center">
              <div class="col-lg-5">
                <h3>Free consultation</h3>
                <p class="my-4">
                  <a href="tel:2149889484" class="">
                    (214) 988-9484
                  </a>
                </p>
                <h4>
                  <a href="mailto:info@capdigisoft.com" class="mb-4">
                    info@capdigisoft.com
                  </a>
                </h4>
              </div>
              <div class="col-lg-7 my-lg-0 my-4">
                <img src={getintouch} class="img-fluid" alt="Get in touch" />
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <h4 class="mb-4">Get in touch</h4>
            
            <Takeaction/>
          </div>
        </div>
      </div>
    </section>
  </div>
  <FooterOne />
</div>
);
};

export default Products;